import request from '@/utils/request'

// 获取我的信息
export function getLoginUserInfo(params) {
  return request({
    url: 'personal/myinfo/getUserinfo',
    params: params,
    method: 'get'
  })
}
// 我的筑龙
export function getMyZhulong(params) {
  return request({
    url: 'personal/myinfo/myzhulong',
    params: params,
    method: 'get'
  })
}
// 我的筑龙 获取筑龙币
export function getMyScore(params) {
  return request({
    url: 'personal/Myinfo/myScore',
    params: params,
    method: 'get'
  })
}
// 我的筑龙，设置防挂机弹框状态
export function setUserPopup(params) {
  return request({
    url: 'personal/Myinfo/setUserPopup',
    params: params,
    method: 'get'
  })
}
// 我的筑龙，获取按钮状态
export function getUserPopup(params) {
  return request({
    url: 'personal/Myinfo/getUserPopup',
    params: params,
    method: 'get'
  })
}

// 获取我的已购课程
export function getBuyLesson(params) {
  return request({
    url: 'personal/Myinfo/myBuyLesson',
    params: params,
    method: 'get'
  })
}
// 获取我的已过期课程
export function myEndLesson(params) {
  return request({
    url: 'personal/Myinfo/myEndLesson',
    params: params,
    method: 'get'
  })
}
// 获取推荐的课程
export function getCommendLesson(params) {
  return request({
    url: 'personal/MyInfo/recommendLessonList',
    params: params,
    method: 'get'
  })
}
// 我的待支付课程
export function getWaitorOrders(params) {
  return request({
    url: 'personal/Myinfo/myPayingLesson',
    params: params,
    method: 'get'
  })
}

// 取消订单
export function getCancelOrder(params) {
  return request({
    url: 'personal/Myinfo/cancelOrder',
    params: params,
    method: 'get'
  })
}

// 我的收藏的课程
export function getMyCollectLesson(params) {
  return request({
    url: 'personal/Myinfo/myCollectLesson',
    params: params,
    method: 'get'
  })
}
// 取消收藏课程
export function cancelCollectCourse(params) {
  return request({
    url: 'personal/Myinfo/cancelCollectLesson',
    params: params,
    method: 'get'
  })
}
// 获取我的快递列表
export function getExpressList(params) {
  return request({
    url: 'personal/Express',
    params: params,
    method: 'get'
  })
}
// 获取我的快递信息
export function getTrackingList(params) {
  return request({
    url: 'gift/gift/logistic',
    // url: 'personal/Express/details',
    params: params,
    method: 'get'
  })
}
// 获取兑换记录快递信息
export function Scorelogistic(params) {
  return request({
    url: 'gift/gift/Scorelogistic',
    // url: 'personal/Express/details',
    params: params,
    method: 'get'
  })
}
// 确认收货
export function confirmExpress(params) {
  return request({
    url: 'personal/Express/confirm',
    params: params,
    method: 'get'
  })
}
// 用户档案
export function myArchiveInfo(params) {
  return request({
    url: 'personal/Myinfo/myArchive',
    params: params,
    method: 'get'
  })
}
// 我的筑龙--推荐关注--关注用户
export function follows(params) {
  return request({
    url: 'personal/myinfo/follows',
    params: params,
    method: 'get'
  })
}
// 我的筑龙--推荐关注--取消关注用户
export function cancelfollow(params) {
  return request({
    url: 'personal/myinfo/cancelFollow',
    params: params,
    method: 'get'
  })
}

// *****==========  ↓↓  分割线  我的社区、我的资料、我的收藏   请求开始  ↓↓  ==========*****
// 我的社区--我关注的小组
export function myOfUser(params) {
  return request({
    url: 'user/bbs/careOfUser',
    method: 'get',
    params: params
  })
}

// 我的社区--我关注的小组
export function mygroup(params) {
  return request({
    url: 'personal/myinfo/myAttendGroup',
    method: 'get',
    params: params
  })
}

// 我的社区--我的发帖
export function mypost(params) {
  return request({
    url: 'personal/myinfo/myPost',
    method: 'get',
    params: params
  })
}

// 我的社区--我的回帖
export function myreplypost(params) {
  return request({
    url: 'personal/myinfo/myReplyPost',
    method: 'get',
    params: params
  })
}

// 我的资料--我的下载
export function mydown(params) {
  return request({
    url: 'personal/Materials/download',
    method: 'get',
    params: params
  })
}

// 我的资料--我的上传
export function myupload(params) {
  return request({
    url: 'personal/Materials/upload',
    method: 'get',
    params: params
  })
}

// 我的资料---收藏的资料
export function mycollection(params) {
  return request({
    url: 'personal/Materials/collection',
    method: 'get',
    params: params
  })
}

// 我的收藏--收藏的资料
export function mycollection_data(params) {
  return request({
    url: 'personal/Myinfo/myCollectResource',
    method: 'get',
    params: params
  })
}

// 我的收藏--收藏的帖子
export function mycollection_thread(params) {
  return request({
    url: 'personal/Myinfo/myCollectInvatation',
    method: 'get',
    params: params
  })
}

// *****==========  ↑↑  分割线  我的社区、我的资料、我的收藏   请求开始  ↑↑  ==========*****

// 个人主页 -- 发帖
export function homepageThread(params) {
  return request({
    url: 'personal/myinfo/myPost',
    method: 'get',
    params: params
  })
}

// 发票状态
export function getInvoiceStatus(params) {
  return request({
    url: 'lesson/invoice/getInvoiceStatus',
    method: 'get',
    params: params
  })
}

// 开发票，获取发票类型、发票内容等
export function InvoiceSetInfo(params) {
  return request({
    url: 'lesson/Invoice/getInvoiceSetInfo',
    method: 'get',
    params: params
  })
}

// 开发票，保存
export function SaveInvoice(params) {
  return request({
    url: 'lesson/invoice/saveInvoice',
    method: 'get',
    params: params
  })
}

// 开发票，保存
export function editOrderAddress(params) {
  return request({
    url: 'personal/Myinfo/editOrderAddress',
    method: 'get',
    params: params
  })
}
// 免费课程
export function myFreeLesson(params) {
  return request({
    url: 'personal/Myinfo/myFreeLesson',
    method: 'get',
    params: params
  })
}
// 我收藏的专题
export function getVipCardCollection(params) {
  return request({
    url: 'personal/Myinfo/getVipCardCollection',
    method: 'get',
    params: params
  })
}
// 获取是否显示赠送e会员弹窗
export function getCanSend(params) {
  return request({
    url: 'ucenter/miniprogram/getCanSend',
    method: 'get',
    params: params
  })
}
